export namespace Enums {

    export enum ReceivedDocumentType {
        FormA = 1,
        FormB = 2,
        FormD = 3,
        BI1663 = 4,
        DHA14 = 5,
        DHA5 = 6,
        IDCopy = 7
    };

    export enum GeneratedDocumentType {
        FormC = 1,
        FormE = 2
    };

    export enum PersonStatus {
        Receving = 1,
        Processing = 2,
        Scheduling = 3,
        Delivery = 4,
        Completed = 5
    }

    export enum OutstandingDocumentType {
        ReceivingRequired = 1,
        ReceivingOptional = 2,
        ProcessingRequired = 3
    }
};