import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SnackbarService } from "app/core/services/snackbar.service";
import { Observable } from "rxjs";
import { CreateOven, Oven, UpdateOven } from "./oven.types";

@Injectable()
export class OvenService {
    constructor(
        private _httpClient: HttpClient,
        private snackbarService: SnackbarService) { }

    createOven = (request: CreateOven): Observable<number> =>
        this._httpClient.post<number>('api/Ovens', request)
            .alertError(this.snackbarService, 'Failed to create Oven');

    updateOven = (request: UpdateOven): Observable<void> =>
        this._httpClient.put<void>(`api/Ovens/${request.id}`, request)
            .alertError(this.snackbarService, 'Failed to update Oven');

    getOvens = (): Observable<Oven[]> =>
        this._httpClient.get<CreateOven[]>('api/Ovens')
            .alertError(this.snackbarService, 'Failed to get Ovens');

    getOven = (id: string): Observable<Oven> =>
        this._httpClient.get<CreateOven>(`api/Ovens/${id}`)
            .alertError(this.snackbarService, 'Failed to get Oven');

    findOven(id: string): Observable<boolean> {
        return this._httpClient.get<boolean>(`api/ovens/${id}/find`);
    }

    createOvenBarcode = (id: string): Observable<any> =>
        this._httpClient.post(`api/ovens/createOvenBarcode`, { ovenId: id })
            .alertError(this.snackbarService, 'Failed to print oven barcode');
}
