import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KEYS } from "app/core/data/core.const";
import { PaginatedList } from "app/core/models/paginated-list.types";
import { SnackbarService } from "app/core/services/snackbar.service";
import { Observable } from "rxjs";
import { ActionStage, CreatePersonReceive, GenerateDocument, Person, PersonFilter, PersonIdNumber, UpdatePersonProcess, UpdatePersonReceive, UpdatePersonSchedule } from "./person.types";

@Injectable()
export class PersonService {
    constructor(
        private _httpClient: HttpClient,
        private snackbarService: SnackbarService) {
    }

    getPersons = (filter: PersonFilter): Observable<PaginatedList<Person>> =>
        this._httpClient.post('api/persons', filter).alertError(this.snackbarService, 'Failed to get persons');

    getPerson = (id: string): Observable<Person> =>
        this._httpClient.get(`api/persons/${id}`)
            .alertError(this.snackbarService, 'Failed to get person');

    getExistingIdNumbers = (): Observable<Array<PersonIdNumber>> =>
        this._httpClient.get(`api/persons/existingIdNumbers`)
            .alertError(this.snackbarService, 'Failed to get existing id numbers');

    findExistingIdNumber = (personId: string, idNumber: string): Observable<boolean> =>
        this._httpClient.get(`api/persons/idNumber/find/${personId}/${idNumber}`)
            .alertError(this.snackbarService, 'Failed to find existing id number');

    findPerson(id: string): Observable<boolean> {
        return this._httpClient.get<boolean>(`api/persons/${id}/find`);
    }

    createPersonBarcode = (id: string): Observable<any> =>
        this._httpClient.post(`api/persons/createBarcode`, { personId: id })
            .alertError(this.snackbarService, 'Failed to create person barcode');

    createPersonLabels = (id: string): Observable<any> =>
        this._httpClient.post(`api/persons/createPersonUrnLabels`, { personId: id })
            .alertError(this.snackbarService, 'Failed to create person urn labels');

    getQrCode = (id: string, actionStage: ActionStage): Observable<string> =>
        this._httpClient.get<string>(`api/persons/${id}/qr`, { params: { actionStage } });

    createPersonReceive = (request: CreatePersonReceive): Observable<string> =>
        this._httpClient.post<string>(`api/persons/receive`, request)
            .alertError(this.snackbarService, 'Failed to create person');

    markPersonInstructionsCompleted = (data: any): Observable<any> =>
        this._httpClient.put(`api/persons/instructions/markCompleted`, data)
            .alertError(this.snackbarService, 'Failed to mark instructions as completed');

    updatePersonReceive = (request: UpdatePersonReceive): Observable<void> =>
        this._httpClient.put<void>(`api/persons/${request.id}/receive`, request)
            .alertError(this.snackbarService, 'Failed to update person');

    removePerson = (id: string): Observable<void> =>
        this._httpClient.put<void>(`api/persons/${id}/remove`, {personId: id})
            .alertError(this.snackbarService, 'Failed to remove person');

    updatePersonProcess = (request: UpdatePersonProcess): Observable<void> =>
        this._httpClient.put<void>(`api/persons/${request.id}/process`, request)
            .alertError(this.snackbarService, 'Failed to update person');

    updatePersonSchedule = (request: UpdatePersonSchedule): Observable<void> =>
        this._httpClient.put<void>(`api/persons/${request.id}/schedule`, request)
            .alertError(this.snackbarService, 'Failed to update person');

    generateDocument = (request: GenerateDocument): Observable<Blob> =>
        this._httpClient.put(`api/persons/${request.personId}/document`, request, { responseType: 'blob' })
            .alertError(this.snackbarService, 'Failed to generate document');

    generateCertificateEnvelope = (personId: string): Observable<Blob> =>
        this._httpClient.put(`api/persons/${personId}/document/certificate/envelope`, { personId } , { responseType: 'blob' })
            .alertError(this.snackbarService, 'Failed to generate certificate envelope');

    setFilter = (actionStage: ActionStage, filter: PersonFilter) =>
        sessionStorage.setItem(`${KEYS.FILTER}-${actionStage.toString()}`, JSON.stringify(filter));

    clearFilter(actionStage: ActionStage) {
        sessionStorage.removeItem(`${KEYS.FILTER}-${actionStage.toString()}`);
    }

    getFilter(actionStage: ActionStage, pageSize: number = 10): PersonFilter {
        const filterString = sessionStorage.getItem(`${KEYS.FILTER}-${actionStage.toString()}`);

        if (!filterString) {
            const filter: PersonFilter = {
                pageNumber: 1,
                pageSize,
                sortDirection: null,
                query: null,
                sortBy: null,
                dateReceivedFrom: null,
                dateReceivedTo: null,
                undertakerId: null,
                outstandingDocuments: [],
                actionStage
            };

            return filter;
        }

        return JSON.parse(filterString);
    }

    genatePersonsExcelFile(filter: PersonFilter): Observable<any>{
        return this._httpClient.post<any>(`api/persons/document/excel`, filter)
            .alertError(this.snackbarService, 'Failed to generate excel file');
    }

    generateCremationCertificate = (id: string): Observable<Blob> =>
        this._httpClient.put(`api/persons/${id}/document/certificate/cremation`, {id} , { responseType: 'blob' })
            .alertError(this.snackbarService, 'Failed to generate cremation certificate');
}
