import { Observable, catchError } from "rxjs";
import { SnackbarService } from "../services/snackbar.service";

Observable.prototype.alertError = function <T>(this: Observable<T>, snackbarService: SnackbarService, message: string): Observable<any> {
    return this.pipe(
        catchError((err, _) => {
            snackbarService.showError(message);
            throw err;
        })
    );
}   