/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:document',
        link: '/reports'
    },
    {
        id: 'operations',
        title: 'Operations',
        type: 'group',
        icon: 'heroicons_outline:cog-6-tooth',
        children: [
            {
                id: 'receiving',
                title: 'Receiving',
                type: 'basic',
                icon: 'heroicons_outline:arrow-down-tray',
                link: '/operations/receivings'
            },
            {
                id: 'processing',
                title: 'Processing',
                type: 'basic',
                icon: 'heroicons_outline:arrow-up-tray',
                link: '/operations/processings'
            },
            {
                id: 'scheduling',
                title: 'Scheduling',
                type: 'collapsable',
                icon: 'heroicons_outline:calendar-days',
                children: [
                    {
                        id: 'scheduling-planner',
                        title: 'Planner',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-plus',
                        link: '/operations/schedulings/planning'
                    },
                    {
                        id: 'scheduling-today',
                        title: 'Today',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-2x2',
                        link: '/operations/schedulings/today'
                    }
                ]
            },
            {
                id: 'delivery',
                title: 'Deliveries',
                type: 'collapsable',
                icon: 'feather:package',
                children: [
                    {
                        id: 'delivery-planner',
                        title: 'Planner',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-plus',
                        link: '/operations/deliveries/planning'
                    },
                    {
                        id: 'delivery-today',
                        title: 'Today',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-2x2',
                        link: '/operations/deliveries/today'
                    }
                ]
            }
        ]
    },
    {
        id: 'admin',
        title: 'Admin',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'application-users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user',
                link: '/admin/application-users'
            },
            {
                id: 'undertakers',
                title: 'Undertakers',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/admin/undertakers'
            },
            {
                id: 'doctors',
                title: 'Doctors',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/admin/doctors'
            },
            {
                id: 'coffin-types',
                title: 'Coffin Types',
                type: 'basic',
                icon: 'heroicons_outline:cube',
                link: '/admin/coffin-types'
            },
            {
                id: 'urn-types',
                title: 'Urn Types',
                type: 'basic',
                icon: 'heroicons_outline:archive-box',
                link: '/admin/urn-types'
            },
            {
                id: 'instructions',
                title: 'Instructions',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/admin/instructions'
            },
            {
                id: 'person-sizes',
                title: 'Person Sizes',
                type: 'basic',
                icon: 'heroicons_outline:square-3-stack-3d',
                link: '/admin/person-sizes'
            },
            {
                id: 'ovens',
                title: 'Ovens',
                type: 'basic',
                icon: 'feather:box',
                link: '/admin/ovens'
            },
            {
                id: 'printer-settings',
                title: 'Printer settings',
                type: 'basic',
                icon: 'mat_outline:settings',
                link: '/admin/printer-settings'
            }
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:document',
        link: '/reports'
    },
    {
        id: 'operations',
        title: 'Operations',
        type: 'group',
        icon: 'heroicons_outline:cog-6-tooth',
        children: [
            {
                id: 'receiving',
                title: 'Receiving',
                type: 'basic',
                icon: 'heroicons_outline:arrow-down-tray',
                link: '/operations/receivings'
            },
            {
                id: 'processing',
                title: 'Processing',
                type: 'basic',
                icon: 'heroicons_outline:arrow-up-tray',
                link: '/operations/processings'
            },
            {
                id: 'scheduling',
                title: 'Scheduling',
                type: 'collapsable',
                icon: 'heroicons_outline:calendar-days',
                children: [
                    {
                        id: 'scheduling-planner',
                        title: 'Planner',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-plus',
                        link: '/operations/schedulings/planning'
                    },
                    {
                        id: 'scheduling-today',
                        title: 'Today',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-2x2',
                        link: '/operations/schedulings/today'
                    }
                ]
            },
            {
                id: 'delivery',
                title: 'Deliveries',
                type: 'collapsable',
                icon: 'feather:package',
                children: [
                    {
                        id: 'delivery-planner',
                        title: 'Planner',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-plus',
                        link: '/operations/deliveries/planning'
                    },
                    {
                        id: 'delivery-today',
                        title: 'Today',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-2x2',
                        link: '/operations/deliveries/today'
                    }
                ]
            }
        ]
    },
    {
        id: 'admin',
        title: 'Admin',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'application-users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user',
                link: '/admin/application-users'
            },
            {
                id: 'undertakers',
                title: 'Undertakers',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/admin/undertakers'
            },
            {
                id: 'doctors',
                title: 'Doctors',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/admin/doctors'
            },
            {
                id: 'coffin-types',
                title: 'Coffin Types',
                type: 'basic',
                icon: 'heroicons_outline:cube',
                link: '/admin/coffin-types'
            },
            {
                id: 'urn-types',
                title: 'Urn Types',
                type: 'basic',
                icon: 'heroicons_outline:archive-box',
                link: '/admin/urn-types'
            },
            {
                id: 'instructions',
                title: 'Instructions',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/admin/instructions'
            },
            {
                id: 'person-sizes',
                title: 'Person Sizes',
                type: 'basic',
                icon: 'heroicons_outline:square-3-stack-3d',
                link: '/admin/person-sizes'
            }
        ]
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:document',
        link: '/reports'
    },
    {
        id: 'operations',
        title: 'Operations',
        type: 'group',
        icon: 'heroicons_outline:cog-6-tooth',
        children: [
            {
                id: 'receiving',
                title: 'Receiving',
                type: 'basic',
                icon: 'heroicons_outline:arrow-down-tray',
                link: '/operations/receivings'
            },
            {
                id: 'processing',
                title: 'Processing',
                type: 'basic',
                icon: 'heroicons_outline:arrow-up-tray',
                link: '/operations/processings'
            },
            {
                id: 'scheduling',
                title: 'Scheduling',
                type: 'collapsable',
                icon: 'heroicons_outline:calendar-days',
                children: [
                    {
                        id: 'scheduling-planner',
                        title: 'Planner',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-plus',
                        link: '/operations/schedulings/planning'
                    },
                    {
                        id: 'scheduling-today',
                        title: 'Today',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-2x2',
                        link: '/operations/schedulings/today'
                    }
                ]
            },
            {
                id: 'delivery',
                title: 'Deliveries',
                type: 'collapsable',
                icon: 'feather:package',
                children: [
                    {
                        id: 'delivery-planner',
                        title: 'Planner',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-plus',
                        link: '/operations/deliveries/planning'
                    },
                    {
                        id: 'delivery-today',
                        title: 'Today',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-2x2',
                        link: '/operations/deliveries/today'
                    }
                ]
            }
        ]
    },
    {
        id: 'admin',
        title: 'Admin',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'application-users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user',
                link: '/admin/application-users'
            },
            {
                id: 'undertakers',
                title: 'Undertakers',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/admin/undertakers'
            },
            {
                id: 'doctors',
                title: 'Doctors',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/admin/doctors'
            },
            {
                id: 'coffin-types',
                title: 'Coffin Types',
                type: 'basic',
                icon: 'heroicons_outline:cube',
                link: '/admin/coffin-types'
            },
            {
                id: 'urn-types',
                title: 'Urn Types',
                type: 'basic',
                icon: 'heroicons_outline:archive-box',
                link: '/admin/urn-types'
            },
            {
                id: 'instructions',
                title: 'Instructions',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/admin/instructions'
            },
            {
                id: 'person-sizes',
                title: 'Person Sizes',
                type: 'basic',
                icon: 'heroicons_outline:square-3-stack-3d',
                link: '/admin/person-sizes'
            }
        ]
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'basic',
        icon: 'heroicons_outline:document',
        link: '/reports'
    },
    {
        id: 'operations',
        title: 'Operations',
        type: 'group',
        icon: 'heroicons_outline:cog-6-tooth',
        children: [
            {
                id: 'receiving',
                title: 'Receiving',
                type: 'basic',
                icon: 'heroicons_outline:arrow-down-tray',
                link: '/operations/receivings'
            },
            {
                id: 'processing',
                title: 'Processing',
                type: 'basic',
                icon: 'heroicons_outline:arrow-up-tray',
                link: '/operations/processings'
            },
            {
                id: 'scheduling',
                title: 'Scheduling',
                type: 'collapsable',
                icon: 'heroicons_outline:calendar-days',
                children: [
                    {
                        id: 'scheduling-planner',
                        title: 'Planner',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-plus',
                        link: '/operations/schedulings/planning'
                    },
                    {
                        id: 'scheduling-today',
                        title: 'Today',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-2x2',
                        link: '/operations/schedulings/today'
                    }
                ]
            },
            {
                id: 'delivery',
                title: 'Deliveries',
                type: 'collapsable',
                icon: 'feather:package',
                children: [
                    {
                        id: 'delivery-planner',
                        title: 'Planner',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-plus',
                        link: '/operations/deliveries/planning'
                    },
                    {
                        id: 'delivery-today',
                        title: 'Today',
                        type: 'basic',
                        icon: 'heroicons_outline:squares-2x2',
                        link: '/operations/deliveries/today'
                    }
                ]
            }
        ]
    },
    {
        id: 'admin',
        title: 'Admin',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'application-users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user',
                link: '/admin/application-users'
            },
            {
                id: 'undertakers',
                title: 'Undertakers',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/admin/undertakers'
            },
            {
                id: 'doctors',
                title: 'Doctors',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: '/admin/doctors'
            },
            {
                id: 'coffin-types',
                title: 'Coffin Types',
                type: 'basic',
                icon: 'heroicons_outline:cube',
                link: '/admin/coffin-types'
            },
            {
                id: 'urn-types',
                title: 'Urn Types',
                type: 'basic',
                icon: 'heroicons_outline:archive-box',
                link: '/admin/urn-types'
            },
            {
                id: 'instructions',
                title: 'Instructions',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document-check',
                link: '/admin/instructions'
            },
            {
                id: 'person-sizes',
                title: 'Person Sizes',
                type: 'basic',
                icon: 'heroicons_outline:square-3-stack-3d',
                link: '/admin/person-sizes'
            }
        ]
    }
];
