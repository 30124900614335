import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, Observable, throwError } from 'rxjs';

export const apiInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    if (request.url.includes('api') && !request.url.includes('common')) {
        const baseUrl = environment.apiUrl;
        request = request.clone({ url: baseUrl + request.url });
    }

    return next(request).pipe(
        catchError((err, caught) => {
            let message = 'An error occurred - Please contact support';

            if (err.status === 400)
                if (err.error && err.error && err.error.errors) {
                    message = '';

                    for (const key in err.error.errors)
                        for (const error of err.error.errors[key])
                            message += error + '\n';

                    message = message.trim();
                } else
                    message = err.error;
            else if (err.status === 404)
                message = err.error;

            return throwError(() => new Error(message));
        })
    );
};