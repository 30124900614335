import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class ScannerDataService {
  private scannedIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  setScannedId(message: string) {
    this.scannedIdSubject.next(message);
  }

  getScannedId() {
    return this.scannedIdSubject.asObservable();
  }

}
