import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({ providedIn: 'root' })
export class SnackbarService {
    constructor(private snackbar: MatSnackBar) { }

    showError(message: string): void {
        this.snackbar.open(message, 'close', {
            duration: 5000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            panelClass: [
                'border-4',
                'border-red-400',
                'border-solid',
                'rounded-lg'
            ],
        });
    }

    showSuccess(message: string): void {
        this.snackbar.open(message, 'close', {
            duration: 5000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            panelClass: [
                'border-4',
                'border-green-400',
                'border-solid',
                'rounded-lg'
            ],
        });
    }
}