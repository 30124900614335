import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { Provider, EnvironmentProviders } from "@angular/core";
import { apiInterceptor } from "../interceptors/api.interceptor";

export const provideApi = (): Array<Provider | EnvironmentProviders> =>
{
    return [
        provideHttpClient(withInterceptors([apiInterceptor]))
    ];
};
