import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { NoAuthGuard } from 'app/core/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { cleanUpSessionGuard } from './core/guards/clean-up.guard';
import { ActionStage } from './modules/person/person.types';
import { KEYS } from './core/data/core.const';
import { PersonService } from './modules/person/person.service';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.


    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
        ]
    },

    // Admin routes
    {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: '', redirectTo: 'application-users', pathMatch: 'full' },
            { path: 'application-users', loadChildren: () => import('app/modules/application-user/application-user.routes') },
            { path: 'doctors', loadChildren: () => import('app/modules/doctor/doctor.routes') },
            { path: 'instruction', loadChildren: () => import('app/modules/instruction/instruction.routes') },
            { path: 'person-sizes', loadChildren: () => import('app/modules/person-size/person-size.routes') },
            { path: 'undertakers', loadChildren: () => import('app/modules/undertaker/undertaker.routes') },
            { path: 'urn-types', loadChildren: () => import('app/modules/urn-type/urn-type.routes') },
            { path: 'coffin-types', loadChildren: () => import('app/modules/coffin-type/coffin-type.routes') },
            { path: 'instructions', loadChildren: () => import('app/modules/instruction/instruction.routes') },
            { path: 'ovens', loadChildren: () => import('app/modules/oven/oven.routes') },
            { path: 'printer-settings', loadChildren: () => import('app/modules/printer-setting/printer-setting.routes') },
        ]
    },
    // Operations routes
    {
        path: 'operations',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        canDeactivate: [cleanUpSessionGuard([`${KEYS.FILTER}--${ActionStage.Process.toString()}`, `${KEYS.FILTER}-${ActionStage.Receive.toString()}`])],
        children: [
            { path: '', loadChildren: () => import('app/modules/person/person.routes') },
        ]
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/dashboard/dashboard.routes') },
        ]
    },
    {
        path: 'reports',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/report/report.routes') },
        ]
    }
];
