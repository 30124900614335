import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { ApplicationUser } from '../models/user.types';

@Injectable({providedIn: 'root'})
export class UserService
{
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<ApplicationUser> = new ReplaySubject<ApplicationUser>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: ApplicationUser)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<ApplicationUser>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<ApplicationUser>
    {
        return this._httpClient.get<ApplicationUser>('api/users/current').pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: ApplicationUser): Observable<any>
    {
        return this._httpClient.patch<ApplicationUser>('api/users/current', {user}).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        );
    }
}
